import { Icon } from "@components/atoms";
import React from "react";
import { Link } from "react-router-dom";

const Breadcrumbs: React.FC<{ items: { to: string; text: string }[] }> = ({
	items,
}) => {
	return (
		<div className="item-center flex flex-row gap-2 overflow-x-auto">
			{items.map((item, index) => {
				const isLast = index === items.length - 1;

				return isLast ? (
					<span
						className="text-content-secondary whitespace-nowrap text-sm capitalize"
						key={index}
					>
						{item.text}
					</span>
				) : (
					<React.Fragment key={index}>
						<Link
							to={item.to}
							className="whitespace-nowrap text-sm capitalize"
							key={index}
						>
							{item.text}
						</Link>
						<Icon
							type="SLASH"
							className="stroke-content-primary h-3 w-3 self-center"
						/>
					</React.Fragment>
				);
			})}
		</div>
	);
};

export default Breadcrumbs;
