import React from "react";
import { useAccount } from "wagmi";
import { azuro } from "business-logic-gamblino";
import { AnimatePresence } from "framer-motion";

import { PlayedGameList } from "./PlayedGameList";
import { SportsbookStats } from "./SportsbookStats";
import { MyBetsTabs } from "./MyBetsTabs/MyBetsTabs";
import { EmptyState, TelegramBotInfoCard } from "@components/organisms";
import { AzuroTagline } from "src/Pages/SportsbookPage/AzuroTagline/AzuroTagline";
import { PlayedGameListPagination } from "./SportsbookPagination/SportsbookPagination";

const MyBets: React.FC<unknown> = () => {
	const account = useAccount();

	const { error, data } = azuro.useBetsQuery({
		where: {
			actor: account.address?.toLowerCase() || "0x",
		},
	});

	if (!account.isConnected)
		return (
			<div className="flex h-[650px] w-full items-center justify-around">
				<EmptyState
					imageType="WALLET_NOT_CONNECTED"
					heading="Wallet not connected"
					description="Please connect wallet"
				/>
			</div>
		);

	if (error)
		return (
			<div className="flex h-[650px] w-full items-center justify-around">
				<EmptyState
					imageType="ERROR"
					heading="Opss error!"
					description="Something went wrong"
				/>
			</div>
		);

	return (
		<>
			<div className="col-span-full min-h-screen flex-1 gap-4">
				<div className="">
					<p className="text-xl font-bold">My Bets</p>

					<div className="mt-4 min-h-fit lg:hidden">
						<SportsbookStats />
					</div>

					<div className="z-10 mt-3 flex justify-between lg:mt-4">
						<MyBetsTabs className="w-full" />
					</div>

					<AnimatePresence mode="popLayout">
						<PlayedGameList className="mt-6" />
					</AnimatePresence>
					<div className="mt-3 flex w-full flex-row items-center justify-between">
						<AzuroTagline />
						<PlayedGameListPagination />
					</div>
				</div>
			</div>
			<div className="sticky top-6 hidden h-fit w-[322px] lg:block">
				<SportsbookStats />
				<TelegramBotInfoCard className="mt-4" />
			</div>
		</>
	);
};

export { MyBets };
