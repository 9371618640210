import React from "react";

import { cx } from "src/utils";
import { useSportsbookStats } from "../hooks";
import { StatsCardSkeleton } from "./StatsCardSkeleton";
import { TooltipNumberWrapper } from "@components/molecules";

const ProfitCard: React.FC<{ className?: string }> = ({ className }) => {
	const { profit, loading } = useSportsbookStats();

	if (loading)
		return (
			<StatsCardSkeleton title="Profit" imgSrc="/sportsbook/profit.png" />
		);

	return (
		<div
			className={cx(
				"bg-background-tertiary flex items-center justify-between rounded-lg px-3 py-2",
				className,
			)}
		>
			<div className="shrink-0 ">
				<p className="text-content-secondary text-sm">Profit</p>
				<div className="flex gap-1 text-base">
					<p>$</p>
					<TooltipNumberWrapper number={profit} />
				</div>
			</div>

			<img className="h-10 w-10" src="/sportsbook/profit.png" />
		</div>
	);
};

export { ProfitCard };
