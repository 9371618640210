import React from "react";
import { useAccount } from "wagmi";

import { useSearchParams } from "react-router-dom";

import { PlayedGameCard } from "../PlayedGameCard";

import { EmptyTab } from "src/Pages/SportsbookPage/MyBetsSideSection/BetsTabs/EmptyTab";
import { PlayedGameCardSkeleton } from "../PlayedGameCard/skeleton/PlayedGameCardSkeleton";
import { MAX_ITEMS_PER_PAGE } from "../config";
import { filteredBets } from "../helpers";
import { azuro } from "business-logic-gamblino";

import { Bet_OrderBy, OrderDirection } from "azuro-service/src/gql/graphql";
import { cx } from "class-variance-authority";

const PlayedGameList: React.FC<{ className?: string }> = ({ className }) => {
	const [searchParams] = useSearchParams();
	const page = +searchParams.get("page")! || 1;

	const account = useAccount();
	const { data, isLoading: loading } = azuro.useBetsQuery({
		skip: (page - 1) * MAX_ITEMS_PER_PAGE,
		first: MAX_ITEMS_PER_PAGE,
		where: {
			actor: account.address?.toLowerCase() || "0x",
		},

		orderBy: Bet_OrderBy.CreatedBlockNumber,
		orderDirection: OrderDirection.Desc,
	});

	const filter = searchParams.get("filter") || "all";

	if (loading) {
		return (
			<div className={cx("flex flex-col gap-2", className)}>
				<PlayedGameCardSkeleton />
				<PlayedGameCardSkeleton />
				<PlayedGameCardSkeleton />
			</div>
		);
	}

	const bets = data || [];

	if (filteredBets(bets, filter).length === 0) {
		const subtitle = filter
			? `There is no bet with status ${filter}, try other filter`
			: "There is no bets to display";

		return (
			<div className="flex h-[650px] items-center justify-center">
				<EmptyTab
					title="No bets"
					imgSrc="/sportsbook/bet.png"
					subtitle={subtitle}
				/>
			</div>
		);
	}

	return (
		<div className={cx("flex flex-col gap-2", className)}>
			{filteredBets(bets, filter).map((bet) => (
				<PlayedGameCard key={bet.tokenId} bet={bet} />
			))}
		</div>
	);
};

export { PlayedGameList };
