import { type Bet } from "@azuro-org/sdk";
import { Button } from "@components/molecules";
import { cx } from "class-variance-authority";
import React from "react";
import { useSearchParams } from "react-router-dom";
import { useAccount } from "wagmi";
import { MAX_ITEMS_PER_PAGE } from "../config";
import { filteredBets } from "../helpers";
import { azuro } from "business-logic-gamblino";

const SportsbookPagination: React.FC<{ className?: string }> = ({
	className,
}) => {
	const [searchParams, setSearchParams] = useSearchParams();
	const page = +searchParams.get("page")! || 1;
	const filter = searchParams.get("filter") || "all";

	const account = useAccount();

	const { data: bets } = azuro.useBetsQuery({
		first: MAX_ITEMS_PER_PAGE,
		skip: (page - 1) * MAX_ITEMS_PER_PAGE,
		where: {
			actor: account.address?.toLowerCase() || "0x",
		},
	});

	if (!bets) return null;

	const isLastPage =
		filteredBets(bets as unknown as Bet[], filter).length === 0 ||
		filteredBets(bets as unknown as Bet[], filter).length <
			MAX_ITEMS_PER_PAGE;

	const handleNextPage = () => {
		setSearchParams((params) => {
			const newParams = new URLSearchParams(params);
			newParams.set("page", (page + 1).toString());
			return newParams;
		});
	};

	const handlePreviousPage = () => {
		setSearchParams((params) => {
			const newParams = new URLSearchParams(params);
			newParams.set("page", (page - 1).toString());
			return newParams;
		});
	};

	return (
		<div className={cx("flex flex-row items-center gap-2", className)}>
			<Button
				onClick={handlePreviousPage}
				type="outline"
				className="[&>span]:text-xs"
				size="sm"
				disabled={
					page === 1 ||
					(page === 1 &&
						filteredBets(bets as unknown as Bet[], filter)
							.length === 0)
				}
			>
				<Button.Text>Previous</Button.Text>
			</Button>
			<Button
				onClick={handleNextPage}
				type="outline"
				className="[&>span]:text-xs"
				size="sm"
				disabled={isLastPage}
			>
				<Button.Text>Next</Button.Text>
			</Button>
		</div>
	);
};

export { SportsbookPagination as PlayedGameListPagination };
