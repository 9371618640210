import React from "react";

import { cx } from "src/utils";
import { motion } from "framer-motion";

const PlayedGameCardSkeleton: React.FC<{ className?: string }> = ({
	className,
}) => {
	return (
		<motion.div
			initial={{
				y: 0,
				opacity: 0,
			}}
			animate={{
				y: 20,
				opacity: 1,
			}}
			className={cx(
				"border-border-primary bg-background-secondary flex flex-col gap-2 rounded-lg border px-4 py-2",
				className,
			)}
		>
			<div className="flex items-center justify-between text-xs">
				<p className="bg-content-primary/10 h-6 max-w-[25%] grow animate-pulse rounded"></p>

				<div className="bg-content-primary/10 flex h-6 w-10 animate-pulse cursor-pointer items-center gap-2"></div>
			</div>

			<div className="flex flex-col gap-1">
				<div className="bg-content-primary/10 h-[76px] animate-pulse rounded"></div>
			</div>

			<div className="flex flex-col justify-between gap-2 md:flex-row">
				<div className="bg-content-primary/10 flex h-6 w-[15%] animate-pulse items-center gap-2 rounded"></div>
			</div>
		</motion.div>
	);
};

export { PlayedGameCardSkeleton };
