import React from "react";
import dayjs from "dayjs";
import { motion } from "framer-motion";

import { cx } from "src/utils";
import { PlayedMatchCard } from "../../PlayedMatchCard";
import { TooltipNumberWrapper } from "@components/molecules";
import { AssetIcon, Icon, Tooltip } from "@components/atoms";

import { useBlockExplorer } from "src/hooks";
import { Bet } from "azuro-service/src/queries/getBets";
import { truncateEthAddress } from "formatting-service";
import { RedeemBetButton } from "./RedeemBetButton";
import { BetStatusPill } from "./BetStatusPill";
import WalletConnectionWrapper from "@components/organisms/WalletConnectionWrapper/WalletConnectionWrapper";

const PlayedGameCard: React.FC<{ className?: string; bet: Bet }> = ({
	className,
	bet,
}) => {
	const getBlockExplorer = useBlockExplorer();

	return (
		<motion.div
			initial={{
				y: -20,
				opacity: 0,
			}}
			animate={{
				y: 0,
				opacity: 1,
			}}
			className={cx(
				" bg-background-secondary flex flex-col gap-2 rounded-lg  px-4 py-2",
				className,
			)}
		>
			<div className="flex items-center justify-between text-xs">
				<div className="flex items-center gap-2">
					<div className="@xl/dashboard:flex-row flex flex-col items-start gap-2">
						<Tooltip content={<p>{bet.id}</p>}>
							<p className="text-content-secondary">
								ID {truncateEthAddress(bet.id.split("_")[0], 3)}
							</p>
						</Tooltip>

						<Tooltip content={<p>Ticket placed on</p>}>
							<p className="text-content-secondary flex gap-1">
								<Icon
									type="CALENDAR"
									className="stroke-content-secondary h-4 w-4"
								/>
								{dayjs
									.unix(bet?.createdAt)
									.format("D MMM YYYY")}
							</p>
						</Tooltip>
						<Tooltip content={<p>Ticket placed at</p>}>
							<p className="text-content-secondary flex items-center gap-1">
								<Icon
									type="CLOCK"
									className="stroke-content-secondary h-4 w-4"
								/>
								{dayjs.unix(bet?.createdAt).format("HH:mm")}
							</p>
						</Tooltip>
					</div>

					<div>
						<BetStatusPill bet={bet} />
					</div>
				</div>
				<a
					target="_blank"
					rel="noreferrer"
					href={getBlockExplorer(bet.txHash)}
				>
					<div className="text-content-secondary flex cursor-pointer items-center gap-2">
						View{" "}
						<Icon
							type="EXTERNAL"
							className="stroke-content-secondary h-5 w-5"
						/>
					</div>
				</a>
			</div>

			<div className="flex flex-col gap-1">
				{bet.outcomes.map((outcome) => (
					<PlayedMatchCard key={outcome.game?.id} bet={outcome} />
				))}
			</div>

			<div className="flex flex-col justify-between gap-2 md:flex-row">
				<div className="@2xl/dashboard:flex-row flex flex-col gap-4">
					<div className="flex items-center gap-2">
						<p className="text-content-secondary">Bet amout</p>
						<TooltipNumberWrapper number={bet.amount} />
						<AssetIcon type="TETHER" className="h-5 w-5" />
					</div>

					<div className="flex items-center gap-2">
						<p className="text-content-secondary">Total payout</p>
						<TooltipNumberWrapper
							number={
								bet.isCanceled
									? bet.amount
									: bet.possibleWin || 0
							}
						/>
						<AssetIcon type="TETHER" className="h-5 w-5" />
					</div>
				</div>
				<div className="flex gap-2">
					<WalletConnectionWrapper className="h-6">
						<RedeemBetButton bet={bet} />
					</WalletConnectionWrapper>
				</div>
			</div>
		</motion.div>
	);
};

export { PlayedGameCard };
