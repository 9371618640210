import React from "react";
import { Navigate, Outlet, useLocation } from "react-router-dom";

import { Menu, TelegramBotInfoCard } from "@components/organisms";
import { MyBetsSideSection } from "./MyBetsSideSection";
import { DashboardPageSection } from "@components/atoms";
import { MyBetsSideSectionMobile } from "./MyBetsSideSectionMobile";

const SportsbookPage = () => {
	const location = useLocation();

	if (location.pathname === "/sportsbook") {
		return <Navigate to="/sportsbook/hot" />;
	}

	return (
		<>
			<DashboardPageSection
				fullWidth
				className="relative p-4 lg:px-9 lg:py-6"
			>
				<Menu className="mx-0 max-w-none px-0" />
				<div className="-z-1 relative mt-10 flex flex-col gap-x-4 md:flex-row">
					<div className="min-h-screen flex-1 gap-4">
						<Outlet />
					</div>
					<div className="sticky top-6 hidden h-fit w-[322px] lg:block">
						<MyBetsSideSection />
						<TelegramBotInfoCard className="mt-4" />
					</div>
				</div>

				<MyBetsSideSectionMobile />
			</DashboardPageSection>
		</>
	);
};

export default SportsbookPage;
