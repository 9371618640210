import React from "react";
import BetsTabs from "./BetsTabs/BetsTabs";

const MyBetsSideSection = () => {
	return (
		<div className="border-border-primary bg-background-secondary  max-h-[70%] overflow-hidden rounded-2xl border">
			<BetsTabs />
		</div>
	);
};

export { MyBetsSideSection };
