import React from "react";

const GameDetailsSkeleton = () => {
	return (
		<div className="bg-background-secondary mt-[30px] flex h-fit flex-col items-center justify-center rounded-2xl py-6">
			<div className="text-gamblino-light flex flex-row items-center gap-1 text-xs">
				<div className="bg-content-primary/10 h-2 w-10 animate-pulse rounded-sm" />
			</div>
			<span className="mt-2 text-xs">
				<div className="bg-content-primary/10 h-2 w-20 animate-pulse rounded-sm" />
			</span>
			<div className="mt-2 flex w-1/3 flex-row items-center justify-between gap-x-2">
				<div className="flex flex-col items-center">
					<div className="bg-content-primary/10 h-16 w-16 animate-pulse rounded-sm" />
					<div className="bg-content-primary/10 mt-2 h-4 w-28 animate-pulse rounded-sm" />
				</div>
				<div className="flex flex-col items-center">
					<div className="bg-content-primary/10 h-16 w-16 animate-pulse rounded-sm" />
					<div className="bg-content-primary/10 mt-2 h-4 w-28 animate-pulse rounded-sm" />
				</div>
			</div>
		</div>
	);
};

export { GameDetailsSkeleton };
