import React from "react";

const FiltersSkeleton = () => {
	return (
		<div className="flex w-full flex-col justify-between gap-y-4 md:flex-row md:items-center">
			<div className="flex flex-row flex-wrap items-center gap-2">
				<div className="bg-content-primary/10 h-9 w-28 animate-pulse rounded-full" />
				<div className="bg-content-primary/10 h-9 w-28 animate-pulse rounded-full" />
			</div>
			<div className="bg-content-primary/10 h-10 w-72 animate-pulse rounded-sm" />
		</div>
	);
};

export { FiltersSkeleton };
