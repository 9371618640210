const convertToSentenceCase = (inputString: string): string => {
	const words: string[] = inputString
		.replace(/[^a-zA-Z0-9]+/g, " ")
		.trim()
		.split(/\s+/);

	const sentenceCaseArray: string[] = words.map((word, index) => {
		if (index === 0) {
			return word.charAt(0).toUpperCase() + word.slice(1).toLowerCase();
		} else {
			return word.toLowerCase();
		}
	});

	const sentenceCaseSlug: string = sentenceCaseArray.join(" ");

	return sentenceCaseSlug;
};

export { convertToSentenceCase };
