import { Icon } from "@components/atoms";
import { azuro, useLoadImageQuery } from "business-logic-gamblino";
import dayjs from "dayjs";
import React from "react";
import { useParams } from "react-router-dom";
import { GameDetailsSkeleton } from "../skeletons/GameDetailsSkeleton";

const GameDetails: React.FC = () => {
	const { gameId } = useParams();

	const {
		data: game,
		isLoading,
		isFetching,
	} = azuro.useGameQuery({
		id: gameId || "",
	});

	const { isError: firstParticipantImageError } = useLoadImageQuery({
		src: game?.participants[0].image || "",
	});

	const { isError: secondParticipantImageError } = useLoadImageQuery({
		src: game?.participants[1].image || "",
	});

	if (isLoading || isFetching) return <GameDetailsSkeleton />;

	if (!game)
		return (
			<div className="col-span-full">
				No game found with the id {gameId}
			</div>
		);
	return (
		<div className="bg-background-secondary mt-[30px] flex h-fit flex-col items-center justify-center rounded-2xl py-6">
			<div className="text-content-primary flex flex-row items-center gap-1 text-xs">
				<span>{dayjs.unix(game.startsAt).format("D MMM")}</span>
				<div className="flex flex-row items-center gap-[2px]">
					<Icon
						type="CLOCK"
						className="stroke-content-primary h-3 w-3"
					/>
					<span>{dayjs.unix(game.startsAt).format("HH:mm")}</span>
				</div>
			</div>
			<span className="mt-2 text-xs">
				{game.league.country.name} · {game.league.name}
			</span>
			<div className="mt-2 flex w-1/3 flex-row items-center justify-between gap-x-2">
				<div className="flex flex-col items-center">
					<img
						src={
							!firstParticipantImageError
								? (game.participants[0].image as string)
								: "/gamblino-logo-without-text.svg"
						}
						alt=""
						className="h-12 w-12"
					/>
					<span className="mt-1 text-center text-lg">
						{game.participants[0].name}
					</span>
				</div>
				<div className="flex flex-col items-center">
					<img
						src={
							!secondParticipantImageError
								? (game.participants[1].image as string)
								: "/gamblino-logo-without-text.svg"
						}
						alt=""
						className="h-12 w-12"
					/>
					<span className="mt-1 text-center text-lg">
						{game.participants[1].name}
					</span>
				</div>
			</div>
		</div>
	);
};

export default GameDetails;
