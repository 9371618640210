import { AssetIcon } from "@components/atoms";
import { TooltipNumberWrapper } from "@components/molecules";
import { azuro } from "business-logic-gamblino";
import React from "react";
import { cx } from "src/utils";
import { useAccount } from "wagmi";

const AzuroScoreCard: React.FC<{ className?: string }> = ({ className }) => {
	const { address } = useAccount();
	const { data: azuroScore } = azuro.useScoreQuery({
		address: address || "0x000",
	});

	return (
		<div
			className={cx(
				"bg-background-tertiary flex items-center justify-between rounded-lg px-3 py-2",
				className,
			)}
		>
			<div className="shrink-0 ">
				<p className="text-sm opacity-50">Azuro Score</p>
				<div className="flex gap-1 text-base">
					<TooltipNumberWrapper number={azuroScore} />
				</div>
			</div>

			<AssetIcon className="h-10 w-10" type="AZURO" />
		</div>
	);
};

export { AzuroScoreCard };
