import React from "react";

import { Menu } from "@components/organisms";
import { DashboardPageSection } from "@components/atoms";
import { MyBets } from "./MyBets";

const SportsbookMyBets: React.FC<unknown> = () => {
	return (
		<>
			<DashboardPageSection
				fullWidth
				className="relative p-4 lg:px-9 lg:py-6"
			>
				<Menu className="mx-0 max-w-none px-0" />

				<div className="-z-1 relative mt-10 flex flex-col gap-x-4 md:flex-row">
					<MyBets />
				</div>
			</DashboardPageSection>
		</>
	);
};

export { SportsbookMyBets };
