import { cx } from "class-variance-authority";
import React from "react";

const AzuroTagline: React.FC<{ className?: string }> = ({ className }) => {
	return (
		<div
			className={cx(
				"flex flex-row items-center gap-1 text-xs",
				className,
			)}
		>
			<span>Powered by</span>
			<a href="https://azuro.org/" target="_blank" rel="noreferrer">
				<img
					src="https://azuro-protocol.notion.site/image/https%3A%2F%2Fprod-files-secure.s3.us-west-2.amazonaws.com%2F4c185184-502e-40d0-818a-d857bac63567%2F840f8c31-a456-49ab-96e5-5a097c42188c%2FAzuro_token_full.png?table=block&id=1204bb1a-ef9f-4d63-8afa-20985729886e&spaceId=4c185184-502e-40d0-818a-d857bac63567&width=2000&userId=&cache=v2"
					className="h-[20px]"
				></img>
			</a>
		</div>
	);
};

export { AzuroTagline };
