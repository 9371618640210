import { getMarketName, getSelectionName } from "@azuro-org/dictionaries";
import { Button } from "@components/molecules";
import { Condition, Game, Outcome } from "azuro-service/src/gql/graphql";
import React from "react";
import { useDispatch } from "react-redux";
import { azuroSlice } from "src/redux/azuroSlice";
import { useAppSelector } from "src/redux/store";
import { cx } from "src/utils";

type Props = {
	condition: Condition;
	outcome: Outcome;
	game: Game;
	className?: string;
};

const BetButton = ({ condition, outcome, game, className }: Props) => {
	const dispatch = useDispatch();
	const bets = useAppSelector((state) => state.azuro);

	const handleAddToBetSlip = () => {
		if (
			bets.some(
				(bet) =>
					bet.conditionId === condition?.conditionId &&
					bet.outcomeId === outcome.outcomeId,
			)
		) {
			dispatch(
				azuroSlice.actions.deleteBet({
					conditionId: condition?.conditionId,
					outcomeId: outcome.outcomeId,
				}),
			);
			return;
		}
		for (const bet of bets) {
			if (bet.conditionId === condition?.conditionId) {
				dispatch(
					azuroSlice.actions.deleteBet({
						conditionId: bet.conditionId,
						outcomeId: bet.outcomeId,
					}),
				);
			}
		}

		dispatch(
			azuroSlice.actions.addBet({
				conditionName: getMarketName({
					outcomeId: outcome.outcomeId,
				}),
				startsAt: game?.startsAt,
				conditionId: condition.conditionId,
				outcomeId: outcome.outcomeId,
				betOdds: Number(outcome.currentOdds as string),
				participants: game.participants.map(
					(participant) => participant.name,
				),
				coreAddress: condition.core.address,
			}),
		);
	};
	return (
		<Button
			onClick={handleAddToBetSlip}
			type="ghost"
			className={cx(
				"bg-background-tertiary flex max-h-[40px] grow flex-row items-center justify-between rounded-[4px]  p-2 text-sm",
				className,
				{
					"bg-content-primary text-content-tertiary": bets.some(
						(bet) =>
							bet.conditionId === condition?.conditionId &&
							bet.outcomeId === outcome.outcomeId,
					),
				},
			)}
		>
			<span>
				{getSelectionName({
					outcomeId: outcome.outcomeId,
					withPoint: true,
				})}
			</span>
			<span>{Number(outcome.currentOdds).toFixed(2)}</span>
		</Button>
	);
};

export default BetButton;
