import React from "react";
import { Filters } from "../Filters/Filters";
import HotSports from "../HotSports/HotSports";
import GamesList from "../GamesList/GamesList";
import { useParams } from "react-router-dom";

const Games = () => {
	const { sportSlug } = useParams();

	return (
		<>
			{sportSlug === "hot" ? (
				<HotSports />
			) : (
				<>
					<Filters />
					<GamesList />
				</>
			)}
		</>
	);
};

export default Games;
