import React from "react";
import { useAccount } from "wagmi";

import { cx } from "src/utils";

import { UserAvatarAddress } from "./UserAvatarAddress";
import { motion } from "framer-motion";
import { ProfitCard } from "./cards/ProfitCard";
import { PlayedBetsCard } from "./cards/PlayedBetsCard";
import { WinRateCard } from "./cards/WinRateCard";
import { TotalVolumeCard } from "./cards/TotalVolumeCard";
import { AzuroScoreCard } from "./cards/AzuroScoreCard";

const SportsbookStats: React.FC<{ className?: string }> = ({ className }) => {
	const account = useAccount();

	if (!account.isConnected) return <></>;

	return (
		<motion.div
			exit={{ opacity: 0 }}
			initial={{
				opacity: 0,
			}}
			animate={{
				opacity: 1,
			}}
			transition={{ duration: 2 }}
			className={cx("bg-background-secondary rounded-2xl", className)}
		>
			<UserAvatarAddress />

			<div className="border-background-primary w-full border border-t"></div>

			<div className="grid grid-cols-2 gap-2 p-4  lg:grid-cols-1 xl:grid-cols-2 ">
				<ProfitCard />

				<PlayedBetsCard />

				<WinRateCard />

				<TotalVolumeCard />

				<AzuroScoreCard className="col-span-2" />
			</div>
		</motion.div>
	);
};

export { SportsbookStats };
