import { Button } from "@components/molecules";
import { useLoadImageQuery } from "business-logic-gamblino";
import React from "react";
import { cx } from "src/utils";
import { motion } from "framer-motion";

const BG_IMAGE_URL = "/telegram-bot-background.png";

const TelegramBotInfoCard: React.FC<{ className?: string }> = ({
	className,
}) => {
	const { isSuccess } = useLoadImageQuery({
		src: BG_IMAGE_URL,
	});

	return (
		<motion.div
			className={cx(
				"bg-purple-dark border-violet-light/10 relative overflow-hidden rounded-2xl border",
				className,
			)}
			initial={{ opacity: 0 }}
			animate={{ opacity: isSuccess ? 1 : 0 }}
		>
			<img
				className="absolute inset-0 z-0 h-full w-full object-cover opacity-30"
				src={BG_IMAGE_URL}
				alt=""
			/>
			<div className="z-1 relative p-4">
				<p className="text-left text-lg font-medium">
					Follow your bets on Telegram!
				</p>

				<p className="mt-3 text-sm">
					Get notified about Gamblino events and bonuses with our
					Telegram bot 🚀
				</p>

				<div className="relative mt-8 w-max">
					<p className="bg-purple-light text-content-primary absolute left-1/2 top-0 z-[2] -translate-x-1/2 -translate-y-2/3 whitespace-nowrap rounded-2xl px-2 py-px text-[10px]">
						COMING SOON
					</p>

					<Button
						onClick={() => {
							console.log("Connect Now");
						}}
						size="sm"
						type="outline"
						disabled
					>
						<Button.Text>Connect Now</Button.Text>
					</Button>
				</div>
			</div>
		</motion.div>
	);
};

export { TelegramBotInfoCard };
