import React from "react";
import { motion } from "framer-motion";

import { useAppSelector } from "src/redux/store";
import { useAccount } from "wagmi";
import { azuro } from "business-logic-gamblino";

const TabsToggle: React.FC<{
	selected: boolean;
	onSelectedChange: (newSelected: boolean) => void;
}> = ({ selected, onSelectedChange }) => {
	const bets = useAppSelector((state) => state.azuro);

	const account = useAccount();
	const { data: placedBets } = azuro.useBetsQuery({
		where: {
			actor: account.address?.toLowerCase() || "0x",
		},
	});

	if (!placedBets) return [];

	const activeBets = placedBets.filter(
		(placedBet) => placedBet.status === "Accepted",
	);

	const betSlipTitle = bets.length ? `Betslip (${bets?.length})` : "Betslip";

	const myBetsTitle = activeBets.length
		? `MyBets (${activeBets?.length})`
		: "MyBets";

	return (
		<div className="px-4 py-2 lg:px-2">
			<div
				className={
					"bg-background-primary relative h-[60px] w-full rounded-full shadow-inner "
				}
			>
				<motion.div
					className="z-1 bg-background-tertiary absolute bottom-1 top-1 w-1/2 rounded-full"
					style={{ left: "4px" }}
					animate={{
						left: !selected ? "4px" : "",
						right: selected ? "4px" : "",
					}}
				></motion.div>
				<div className="flex h-full w-full items-center justify-evenly">
					<span
						onClick={() => onSelectedChange(false)}
						className="z-2 w-full cursor-pointer select-none p-3 text-center font-medium"
					>
						{betSlipTitle}
					</span>
					<span
						onClick={() => onSelectedChange(true)}
						className="z-2 w-full cursor-pointer select-none p-3 text-center font-medium"
					>
						{myBetsTitle}
					</span>
				</div>
			</div>
		</div>
	);
};

export { TabsToggle };
