import React from "react";
import { MarketAccotdionSkeleton } from "../MarketAccordion/skeleton/MarketAccotdionSkeleton";

const GameConditionsSkeleton = () => {
	return (
		<div className="mt-4 flex w-full flex-col gap-2">
			{[...Array(5)].map((num) => (
				<MarketAccotdionSkeleton key={num} />
			))}
		</div>
	);
};

export { GameConditionsSkeleton };
