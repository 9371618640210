const MAX_ITEMS_PER_PAGE = 10;

const betsTypes = [
	{
		slug: "all",
		name: "All",
	},
	{
		slug: "unclaimed",
		name: "Unclaimed",
	},
	{
		slug: "active",
		name: "Active",
	},
	{
		slug: "canceled",
		name: "Canceled",
	},
	{
		slug: "won",
		name: "Won",
	},
	{
		slug: "lost",
		name: "Lost",
	},
];

export { MAX_ITEMS_PER_PAGE, betsTypes };
