import React from "react";
import { useAccount } from "wagmi";
import { cx } from "class-variance-authority";
import { truncateEthAddress } from "formatting-service";

import { Icon } from "@components/atoms";
import { Avatar } from "@components/molecules";
import { useBlockExplorer } from "src/hooks";

const UserAvatarAddress: React.FC<{ className?: string }> = ({ className }) => {
	const account = useAccount();
	const getBlockExplorer = useBlockExplorer();

	const addressTruncated = truncateEthAddress(account?.address || "0x0");

	if (!account.address) return <></>;

	return (
		<a
			target="_blank"
			rel="noreferrer"
			href={getBlockExplorer(account.address)}
		>
			<div className={cx("flex items-center gap-2 p-4", className)}>
				<Avatar userAddress={account.address || "0x0"} />
				<p>{addressTruncated}</p>
				<Icon
					type="EXTERNAL"
					className="stroke-content-primary h-5 w-5"
				/>
			</div>
		</a>
	);
};

export { UserAvatarAddress };
