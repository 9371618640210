import { RadioGroup } from "@headlessui/react";
import React from "react";
import { cx } from "src/utils";

interface TimeslotOptionProps {
	value: string;
	label: string;
}

const TimeslotOption: React.FC<TimeslotOptionProps> = ({ value, label }) => {
	return (
		<RadioGroup.Option value={value}>
			{({ checked }) => (
				<span
					className={cx(
						"hover:bg-content-primary/5 cursor-pointer select-none rounded-3xl px-3 py-2",
						{
							"bg-content-primary/10 hover:bg-content-primary/10":
								checked,
						},
					)}
				>
					{label}
				</span>
			)}
		</RadioGroup.Option>
	);
};

export default TimeslotOption;
