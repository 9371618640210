import { Bet } from "azuro-service/src/queries/getBets";

const filteredBets = (bets: Bet[], filter?: string | null) => {
	if (!filter) {
		return bets;
	}

	if (filter === "lost") {
		return bets.filter((bet) => bet.isLose);
	}

	if (filter === "active") {
		return bets.filter((bet) => bet.isActive);
	}

	if (filter === "canceled") {
		return bets.filter((bet) => bet.isCanceled && bet.isRedeemed);
	}

	if (filter === "won") {
		return bets.filter((bet) => bet.isWin);
	}

	if (filter === "unclaimed") {
		return bets.filter((bet) => bet.isRedeemable);
	}

	return bets;
};

export { filteredBets };
