import { Game, Sport, SportHub } from "azuro-service/src/gql/graphql";
import React from "react";
import { CountryLeagues } from "../CountryLeagues/CountryLeagues";
import NoEventsCard from "../NoEventsCard/NoEventsCard";
import { filterGamesByTimeslot } from "../helpers";
import { useParams, useSearchParams } from "react-router-dom";
import { azuro } from "business-logic-gamblino";
import CountryLeaguesSkeleton from "../CountryLeagues/skeleton/CountryLeaguesSkeleton";

const HotSports: React.FC = () => {
	const { sportSlug } = useParams();
	const [searchParams] = useSearchParams();
	const timeslot = searchParams.get("timeslot") || "all";

	const { data, isLoading, isFetching } = azuro.useGamesListQuery({
		hotSports: sportSlug === "hot",
		variables: {
			sportsSlug: sportSlug || "",
			countrySlug: searchParams.get("country") || "",
			leagueSlug: searchParams.get("league") || "",
			gamesLimit: 3,
			sportsSlugs: ["football", "basketball", "mma", "ice-hockey"],
		},
	});

	if (isLoading || isFetching) {
		return [...Array(5)].map((num) => <CountryLeaguesSkeleton key={num} />);
	}

	if (!data) return null;

	const sportHub = data.find((item) => item.name === "sports") as SportHub;

	const sports = sportHub.sports?.filter(
		(sport) => sport.countries.length > 0,
	) as Sport[];

	const countries = sports
		?.map((sport) => sport.countries)
		.flat()
		.filter((country) => country.leagues.length > 0);

	if (!countries) return null;

	const leagues = countries.map((country) => country.leagues).flat();

	const hasEvents =
		countries.length > 0 &&
		leagues.length > 0 &&
		countries.some((country) =>
			country.leagues.some((league) =>
				league.games.some((game) =>
					filterGamesByTimeslot(timeslot, game),
				),
			),
		);

	return hasEvents ? (
		sports?.map((sport) =>
			sport.countries.map((country, i) => (
				<React.Fragment key={`${country.id}-${i}`}>
					<span className="-mb-2 text-lg">Top {sport.name}</span>
					<CountryLeagues country={country} />
				</React.Fragment>
			)),
		)
	) : (
		<NoEventsCard />
	);
};

export default HotSports;
