import React from "react";
import { azuro } from "business-logic-gamblino";

import { NavCollapse, NavItem } from "./Nav";
import { StatusPill } from "@components/atoms";
import { filterGamesByTimeslot } from "src/Pages/GamesListPage/helpers";

const SportsbookItems = () => {
	const { data, isLoading, isFetching } = azuro.useSportsListQuery(undefined);

	if (isLoading || isFetching)
		return (
			<NavItem
				text={"Sportsbook"}
				iconType={"LOADER"}
				isActive={false}
				iconClassName="animate-spin"
			/>
		);

	if (!data) return null;

	return (
		<NavCollapse
			id="sportsbook"
			to="/sportsbook"
			text="Sportsbook"
			iconType="BOOK_OPEN_TEXT"
		>
			<NavItem
				id={"hot"}
				to={`/sportsbook/hot`}
				text={"Hot Events"}
				iconType={"FLAME"}
			></NavItem>
			{data.map((sportType) => {
				const sports = sportType.sports
					? [...sportType.sports].sort(
							(a, b) => b.games.length - a.games.length,
						)
					: [];

				return (
					<React.Fragment key={sportType.id}>
						{sports.map((sport) => {
							if (sport.games.length <= 0) return null;
							return (
								<NavItem
									key={sport.id}
									id={sport.id}
									to={`/sportsbook/${sport.slug}`}
									text={sport.name}
									iconType={
										sport.slug.toUpperCase() as React.ComponentProps<
											typeof NavItem
										>["iconType"]
									}
								>
									<StatusPill
										type="GAME_COUNT"
										text={sport.games
											.filter((game) => {
												return filterGamesByTimeslot(
													"all",
													game,
												);
											})
											.length.toString()}
									/>
								</NavItem>
							);
						})}
					</React.Fragment>
				);
			})}
		</NavCollapse>
	);
};

export { SportsbookItems };
