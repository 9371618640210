import React from "react";
import { cx } from "src/utils";

const NavPillSkeleton = () => {
	return (
		<div
			className={cx(
				"bg-background-tertiary flex h-9 w-28 select-none flex-row items-center justify-center gap-1 whitespace-nowrap rounded-full px-3 py-2 text-sm hover:opacity-75",
			)}
		>
			<div className="bg-content-primary/10 h-4 w-full animate-pulse rounded-sm" />
		</div>
	);
};

export default NavPillSkeleton;
