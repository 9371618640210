import React from "react";

const GameCardSkeleton = () => {
	return (
		<div className="bg-background-tertiary @lg:flex-row @lg:items-center @lg:gap-0 flex w-full flex-col justify-between gap-2 rounded-[4px] p-2">
			<div className="flex flex-col gap-2">
				<div className="bg-content-primary/10 h-4 w-20 animate-pulse rounded-sm" />
				<div className="bg-content-primary/10 h-4 w-32 animate-pulse rounded-sm" />
			</div>
			<div className="bg-content-primary/10 @lg:w-48 h-10 w-full animate-pulse rounded-sm" />
		</div>
	);
};

export default GameCardSkeleton;
