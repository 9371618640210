import React from "react";
import { useParams } from "react-router-dom";
import NavPills from "./NavPills/NavPills";
import Breadcrumbs from "./Breadcrumbs/Breadcrumbs";
import FeaturedSection from "./FeaturedSection/FeaturedSection";
import Games from "./Games/Games";
import { AzuroTagline } from "../SportsbookPage/AzuroTagline/AzuroTagline";
import { convertToSentenceCase } from "src/utils/convertToSentenceCase";

const GamesListPage = () => {
	const pageRef = React.useRef<HTMLDivElement>(null);
	const { sportSlug } = useParams();

	const breadcrumbsItems: React.ComponentProps<typeof Breadcrumbs>["items"] =
		[
			{
				text: "Sportsbook",
				to: "/sportsbook",
			},
			{
				text: convertToSentenceCase(sportSlug!),
				to: `/sportsbook/${sportSlug!}`,
			},
		];

	return (
		<div className="grid gap-4" ref={pageRef}>
			<div className="flex w-full flex-row items-center justify-between">
				<Breadcrumbs items={breadcrumbsItems} />
			</div>
			{/* <FeaturedSection parent={pageRef} /> */}
			<NavPills />
			<h1 className="text-2xl">
				{convertToSentenceCase(sportSlug!)} Events
			</h1>
			<Games />
			<AzuroTagline className="mt-2" />
		</div>
	);
};

export default GamesListPage;
