import { Icon, Tooltip } from "@components/atoms";
import { Game } from "azuro-service/src/gql/graphql";
import dayjs from "dayjs";
import React from "react";
import { Link } from "react-router-dom";
import { findConditionByMarket } from "../helpers";
import BetButton from "../BetButton/BetButton";

type Props = {
	game: Game;
};

const GameCard = ({ game }: Props) => {
	const fullTimeResultCondition = findConditionByMarket(
		game.conditions,
		game.sport.slug === "football" ? "Full Time Result" : "Match Winner",
	);

	return (
		<div className="bg-background-tertiary @lg:flex-row @lg:items-center @lg:gap-0 flex w-full flex-col justify-between gap-2 rounded-[4px] p-2">
			<div className="flex flex-col">
				<Tooltip content="Your timezone" placement="top-start">
					<div className="text-content-secondary flex flex-row items-center gap-1 text-xs">
						<span>{dayjs.unix(game.startsAt).format("D MMM")}</span>
						<div className="flex flex-row items-center gap-1">
							<Icon
								type="CLOCK"
								className="stroke-content-secondary h-3 w-3"
							/>
							<span>
								{dayjs.unix(game.startsAt).format("HH:mm")}
							</span>
						</div>
					</div>
				</Tooltip>
				<div className="flex justify-between">
					<Link
						to={`/sportsbook/${game.sport.slug}/${
							(game.id || game.gameId) as string
						}`}
						className="@lg:ml-2 flex flex-row items-center gap-1"
					>
						<span>{game.title}</span>
					</Link>

					<div>
						<Link
							to={`/sportsbook/${game.sport.slug}/${
								(game.id || game.gameId) as string
							}`}
							className="@lg:ml-2 @md:hidden text-xs"
						>
							<Icon
								type="CHEVRON_RIGHT"
								className="stroke-content-primary h-4 w-4"
							/>
						</Link>
					</div>
				</div>
			</div>
			<div className="flex flex-row items-center gap-2">
				{fullTimeResultCondition &&
					fullTimeResultCondition?.outcomes.map((outcome) => (
						<BetButton
							key={outcome.id}
							condition={fullTimeResultCondition}
							game={game}
							outcome={outcome}
							className="@lg:gap-x-8 @lg:w-24 bg-background-secondary flex h-fit w-[70px] flex-row gap-x-5 rounded-[4px] p-2 md:p-3"
						/>
					))}
				<div className="grow" />
				<Link
					to={`/sportsbook/${game.sport.slug}/${
						(game.id || game.gameId) as string
					}`}
					className="@lg:ml-2 @md:flex hidden flex-row items-center gap-1 text-xs"
				>
					<span>More</span>
					<Icon
						type="CHEVRON_RIGHT"
						className="stroke-content-primary h-4 w-4"
					/>
				</Link>
			</div>
		</div>
	);
};

export default GameCard;
