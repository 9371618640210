import React from "react";

import { cx } from "src/utils";
import { useSportsbookStats } from "../hooks";
import { StatsCardSkeleton } from "./StatsCardSkeleton";
import { TooltipNumberWrapper } from "@components/molecules";

const TotalVolumeCard: React.FC<{ className?: string }> = ({ className }) => {
	const { totalVolume, loading } = useSportsbookStats();

	if (loading)
		return (
			<StatsCardSkeleton
				title="Total Volume"
				imgSrc="/sportsbook/volume.png"
			/>
		);

	return (
		<div
			className={cx(
				"bg-background-tertiary flex items-center justify-between rounded-lg px-3 py-2",
				className,
			)}
		>
			<div className="shrink-0 ">
				<p className="text-sm text-white">Total Volume</p>
				<div className="flex gap-1 text-base">
					<p>$</p>
					<TooltipNumberWrapper number={totalVolume} />
				</div>
			</div>

			<img className="h-10 w-10" src="/sportsbook/volume.png" />
		</div>
	);
};

export { TotalVolumeCard };
