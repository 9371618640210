import { Icon } from "@components/atoms";
import { Menu } from "@headlessui/react";
import React from "react";
import { useSearchParams } from "react-router-dom";
import { cva, type VariantProps } from "class-variance-authority";
import { cx } from "src/utils";

const menuVariants = cva("relative inline-block rounded-full", {
	variants: {
		variant: {
			default: "bg-primary text-primary-foreground hover:bg-primary/90",
			description:
				"bg-primary text-primary-foreground hover:bg-primary/90",
			gradient: "bg-dropdown-gradient",
		},
	},
	defaultVariants: {
		variant: "default",
	},
});

const menuItemVariants = cva(
	"bg-background-tertiary absolute mt-2 max-h-36 w-[184px] divide-y divide-content-primary overflow-auto rounded-lg shadow-lg focus:outline-none z-sidebar",
	{
		variants: {
			side: {
				left: "left-0 origin-top-left",
				right: "right-0 origin-top-right",
			},
		},
		defaultVariants: {
			side: "left",
		},
	},
);

const SportsbookDropdown: React.FC<{
	items: ({ slug: string; name: string } | null)[];
	type: "country" | "league" | "odds" | "filter";
	label: string;
	variant?: VariantProps<typeof menuVariants>["variant"];
	side?: VariantProps<typeof menuItemVariants>["side"];
	descriptionText?: string;
	withAllOption?: boolean;
	handleOnClick: (item: { slug: string; name: string } | null) => void;
}> = ({
	items,
	type,
	label,
	variant,
	side,
	descriptionText,
	withAllOption,
	handleOnClick,
}) => {
	const [searchParams] = useSearchParams();

	return (
		<Menu
			as="div"
			className={cx(menuVariants({ variant }))}
			defaultValue={searchParams.get(type) || items[0]?.name}
		>
			<div>
				<Menu.Button className="inline-flex w-full justify-center rounded-md px-4 py-2 text-sm font-medium text-white focus:outline-none focus-visible:ring-2 focus-visible:ring-white/75">
					{variant === "description" ? (
						<div className="flex flex-row items-center gap-1">
							<span className="text-content-secondary capitalize">
								{descriptionText}
							</span>
							<span>
								{items.find(
									(item) =>
										item?.slug === searchParams.get(type),
								)?.name || label}
							</span>
						</div>
					) : (
						items.find(
							(item) => item?.slug === searchParams.get(type),
						)?.name || label
					)}
					<Icon
						className="stroke-content-primary ui-open:rotate-180 -mr-1 ml-2 h-5 w-5"
						aria-hidden="true"
						type="CHEVRON_DOWN"
					/>
				</Menu.Button>
			</div>
			<Menu.Items
				className={cx(
					menuItemVariants({
						side,
					}),
				)}
			>
				<div className="p-2">
					{withAllOption && (
						<Menu.Item>
							<button
								className="text-content-primary hover:bg-dropdown-gradient group flex w-full items-center rounded-md px-2 py-2 text-left text-sm"
								onClick={() =>
									handleOnClick({ slug: "all", name: "all" })
								}
							>
								All
							</button>
						</Menu.Item>
					)}
					{items.map((item) => {
						if (!item) return null;
						return (
							<Menu.Item key={item.slug}>
								<button
									className="text-content-primary hover:bg-dropdown-gradient group flex w-full items-center rounded-md px-2 py-2 text-left text-sm"
									onClick={() => handleOnClick(item)}
								>
									{item.name}
								</button>
							</Menu.Item>
						);
					})}
				</div>
			</Menu.Items>
		</Menu>
	);
};

export { SportsbookDropdown };
