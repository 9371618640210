import React from "react";
import Breadcrumbs from "../GamesListPage/Breadcrumbs/Breadcrumbs";
import GameDetails from "./GameDetails/GameDetails";
import GameConditions from "./GameConditions/GameConditions";
import { AzuroTagline } from "../SportsbookPage/AzuroTagline/AzuroTagline";
import { useParams } from "react-router-dom";
import { convertToSentenceCase } from "src/utils/convertToSentenceCase";

const GamePage = () => {
	const { sportSlug, gameId } = useParams();
	const breadcrumbsItems: React.ComponentProps<typeof Breadcrumbs>["items"] =
		[
			{
				text: "Sportsbook",
				to: "/sportsbook",
			},
			{
				text: convertToSentenceCase(sportSlug!),
				to: `/sportsbook/${sportSlug!}`,
			},
			{
				text: gameId!.split("_")[1],
				to: `/sportsbook/${sportSlug!}/${gameId!}`,
			},
		];
	return (
		<div className="col-span-full">
			<Breadcrumbs items={breadcrumbsItems} />
			<GameDetails />
			<GameConditions />
			<AzuroTagline className="mt-2" />
		</div>
	);
};

export default GamePage;
