import { Country, League } from "azuro-service/src/gql/graphql";
import React from "react";
import { SportsbookDropdown } from "../SportsbookDropdown";
import SportsbookTimeslots from "../SportsbookTimeslots/SportsbookTimeslots";
import { useParams, useSearchParams } from "react-router-dom";
import { azuro } from "business-logic-gamblino";
import { FiltersSkeleton } from "./skeletons/FiltersSkeleton";

const Filters: React.FC = () => {
	const { sportSlug } = useParams();

	const [searchParams, setSearchParams] = useSearchParams();

	const sportType = ["dota-2", "csgo"].includes(sportSlug || "")
		? "esports"
		: "sports";

	const { data, isLoading, isFetching } = azuro.useCountriesQuery({
		sportSlug: sportSlug || "",
		sportType,
	});

	const [timeslot, setTimeslot] = React.useState(() => {
		const newParams = new URLSearchParams(window.location.search);

		if (newParams.has("timeslot")) {
			return newParams.get("timeslot") as string;
		}

		return "all";
	});

	if (isLoading || isFetching) {
		return <FiltersSkeleton />;
	}

	if (!data) return null;

	const handleChangeTimeslot = (value: string) => {
		setTimeslot(value);
		setSearchParams((params) => {
			const newParams = new URLSearchParams(params);
			newParams.set("timeslot", value);
			return newParams;
		});
	};

	const countries = (data?.[0]?.sports?.[0]?.countries || []) as Country[];

	const leagues = searchParams.get("country")
		? countries.find(
				(country) =>
					country.slug === (searchParams.get("country") || ""),
		  )?.leagues || []
		: countries.map((country) => country.leagues).flat();

	const countryFilterItems = countries.map((country) => {
		if (country.leagues.length <= 0) return null;
		return {
			slug: country.slug,
			name: country.name,
		};
	});

	const leagueFilterItems = leagues.map((league) => {
		return {
			slug: league.slug,
			name: league.name,
		};
	});

	const handleChangeCountry = (
		item: { slug: string; name: string } | null,
	) => {
		if (!item) return;

		setSearchParams((params) => {
			const newParams = new URLSearchParams(params);

			newParams.delete("league");

			if (item.name === "all") {
				newParams.delete("country");
				return newParams;
			}

			newParams.set("country", item.slug);
			return newParams;
		});
	};

	const handleChangeLeague = (
		item: { slug: string; name: string } | null,
	) => {
		if (!item) return;

		if (item.name === "all") {
			setSearchParams((params) => {
				const newParams = new URLSearchParams(params);

				newParams.delete("league");

				return newParams;
			});
			return;
		}
		setSearchParams((params) => {
			const newParams = new URLSearchParams(params);

			newParams.set("league", item.slug);

			return newParams;
		});
	};

	return (
		<div className="flex w-full flex-col justify-between gap-y-4 md:flex-row md:items-center">
			<div className="flex flex-row flex-wrap items-center gap-2">
				<SportsbookDropdown
					type="country"
					items={countryFilterItems}
					label="Country"
					variant="gradient"
					withAllOption
					handleOnClick={handleChangeCountry}
				/>
				<SportsbookDropdown
					type="league"
					items={leagueFilterItems}
					label="League"
					variant="gradient"
					withAllOption
					handleOnClick={handleChangeLeague}
				/>
			</div>
			<SportsbookTimeslots
				timeslot={timeslot}
				handleChangeTimeslot={handleChangeTimeslot}
			/>
		</div>
	);
};

export { Filters };
