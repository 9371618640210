import React from "react";
import MarketAccordion from "../MarketAccordion/MarketAccordion";
import { azuro } from "business-logic-gamblino";
import { useParams } from "react-router-dom";
import { Condition, Game } from "azuro-service/src/gql/graphql";
import { GameConditionsSkeleton } from "../skeletons/GameConditionsSkeleton";

const GameConditions: React.FC = () => {
	const { gameId } = useParams();

	const {
		data: game,
		isLoading,
		isFetching,
	} = azuro.useGameQuery({
		id: gameId || "",
	});

	if (isLoading || isFetching) return <GameConditionsSkeleton />;

	if (!game)
		return (
			<div className="col-span-full">
				No conditions found for game with the id {gameId}
			</div>
		);
	return (
		<div className="mt-4 flex w-full flex-col gap-2">
			{game.conditions.map((condition) => (
				<MarketAccordion
					key={condition.conditionId}
					condition={condition as Condition}
					game={game as Game}
				/>
			))}
		</div>
	);
};

export default GameConditions;
