import { cx } from "src/utils";
import React from "react";
import { useSearchParams } from "react-router-dom";
import { useAccount } from "wagmi";
import { filteredBets } from "../helpers";
import { azuro } from "business-logic-gamblino";

const MyBetsTabs: React.FC<{ className?: string }> = ({ className }) => {
	const { address } = useAccount();
	const [searchParams, setSearchParams] = useSearchParams();

	const { data } = azuro.useBetsQuery({
		where: {
			actor: address?.toLowerCase() || "0x",
		},
	});

	const tabs = [
		{
			slug: "all",
			name: "All",
			amount: data?.length,
		},
		{
			slug: "unclaimed",
			name: "Unclaimed",
			amount: filteredBets(data || [], "unclaimed").length,
		},
		{
			slug: "active",
			name: "Active",
			amount: filteredBets(data || [], "active").length,
		},
		{
			slug: "canceled",
			name: "Canceled",
			amount: filteredBets(data || [], "canceled").length,
		},
		{
			slug: "won",
			name: "Won",
			amount: filteredBets(data || [], "won").length,
		},
		{
			slug: "lost",
			name: "Lost",
			amount: filteredBets(data || [], "lost").length,
		},
	];

	const filter = searchParams.get("filter") || "all";
	const currentTab = tabs.find((tab) => tab.slug === filter) || tabs[0];

	const handleOnClick = (item: (typeof tabs)[0]) => {
		if (!item) return;
		if (item.name === "all") {
			setSearchParams((params) => {
				const newParams = new URLSearchParams(params);

				if (newParams.has("filter")) {
					newParams.delete("filter");
				}

				return newParams;
			});
			return;
		}
		setSearchParams((params) => {
			const newParams = new URLSearchParams(params);

			newParams.set("filter", item.slug);

			return newParams;
		});
	};

	return (
		<div className={cx("border-border-primary border-b", className)}>
			<div className="overflow-x-auto overflow-y-hidden sm:max-w-md sm:overflow-visible">
				<nav
					className="-mb-px flex space-x-4 sm:space-x-0"
					aria-label="Tabs"
				>
					{tabs.map((tab) => (
						<span
							key={tab.name}
							className={cx(
								tab === currentTab
									? "border-content-primary text-content-primary"
									: "hover:text-content-secondary text-content-primary hover:border-content-secondary border-transparent",
								"flex w-full cursor-pointer select-none flex-row items-center gap-1 whitespace-nowrap border-b-2 px-7 py-3 text-center text-sm font-medium",
							)}
							onClick={() => handleOnClick(tab)}
						>
							<span>{tab.name}</span>
							<span>({tab.amount})</span>
						</span>
					))}
				</nav>
			</div>
		</div>
	);
};

export { MyBetsTabs };
