import { Icon } from "@components/atoms";
import { Sport } from "azuro-service/src/gql/graphql";
import { azuro } from "business-logic-gamblino";
import React from "react";
import { Link, useLocation } from "react-router-dom";
import { cx } from "src/utils";
import NavPillSkeleton from "./skeleton/NavPillSkeleton";

const NavPills: React.FC<{ className?: string }> = ({ className }) => {
	const { data, isLoading, isFetching } = azuro.useSportsListQuery(undefined);

	if (isLoading || isFetching) {
		return (
			<div
				className={cx(
					"flex w-full flex-row items-center gap-1 overflow-x-auto",
					className,
				)}
			>
				{[...Array(10)].map((num, index) => (
					<NavPillSkeleton key={index} />
				))}
			</div>
		);
	}

	if (!data) return null;

	return (
		<div
			className={cx(
				"flex w-full flex-row items-center gap-1 overflow-x-auto",
				className,
			)}
		>
			<>
				<NavPill
					icon="FLAME"
					item={{
						slug: "hot",
						name: "Hot events",
						games: [],
					}}
				/>
				{data.map((sportType) => {
					const sports = sportType.sports
						? [...sportType.sports].sort(
								(a, b) => b.games.length - a.games.length,
						  )
						: [];

					return (
						<React.Fragment key={sportType.id}>
							{sports.map((sport) => {
								if (sport.games.length <= 0) return null;
								return (
									<NavPill
										key={sport.id}
										icon={
											sport.slug.toUpperCase() as React.ComponentProps<
												typeof NavPill
											>["icon"]
										}
										item={sport}
									/>
								);
							})}
						</React.Fragment>
					);
				})}
			</>
		</div>
	);
};

const NavPill: React.FC<{
	item: Sport | { slug: string; name: string; games: any[] };
	icon: React.ComponentProps<typeof Icon>["type"];
}> = ({ item, icon }) => {
	const location = useLocation();

	const isActive = `/sportsbook/${item.slug}` === location.pathname;

	return (
		<Link
			to={`/sportsbook/${item.slug}`}
			className={cx(
				"bg-background-tertiary flex select-none flex-row items-center justify-center gap-1 whitespace-nowrap rounded-full px-3 py-2 text-sm hover:opacity-75",
				{
					"bg-background-secondary text-content-primary opacity-100 hover:opacity-100":
						isActive,
				},
			)}
		>
			<Icon
				type={icon}
				className={cx("h-5 w-5", {
					"stroke-purple-black": isActive,
				})}
			/>
			<span
				className={cx("text-content-primary/50", {
					"text-content-primary": isActive,
				})}
			>
				{item.name}
			</span>

			{item.games?.length > 0 && (
				<span
					className={cx("text-content-primary/50", {
						"text-content-primary": isActive,
					})}
				>
					{`(${item.games.length})`}
				</span>
			)}
		</Link>
	);
};

export default NavPills;
