import React from "react";
import { cx } from "src/utils";

const StatsCardSkeleton: React.FC<{
	className?: string;
	title: string;
	imgSrc: string;
}> = ({ className, title, imgSrc }) => {
	return (
		<div
			className={cx(
				"bg-background-tertiary flex items-center justify-between rounded-lg px-3 py-2",
				className,
			)}
		>
			<div className="shrink-0 ">
				<p className="text-content-secondary text-sm">{title}</p>
				<div className="bg-content-primary/10 flex h-6 w-14 animate-pulse gap-1 rounded text-base"></div>
			</div>

			<img className="h-10 w-10" src={imgSrc} />
		</div>
	);
};

export { StatsCardSkeleton };
