/* eslint-disable @typescript-eslint/restrict-plus-operands */
/* eslint-disable @typescript-eslint/no-unsafe-call */
import { azuro } from "business-logic-gamblino";
import { useAccount } from "wagmi";

const useSportsbookStats = () => {
	const account = useAccount();

	const { data: placedBets, ...rest } = azuro.useBetsQuery({
		where: {
			actor: account.address?.toLowerCase() || "0x0",
		},
	});

	if (!placedBets) return [];

	const wonBets = placedBets.filter((bet) => bet.isWin);
	const winRate = (wonBets.length / placedBets.length) * 100;

	const totalVolume = placedBets.reduce((prev, curr) => {
		return prev + parseFloat(curr.amount);
	}, 0);

	const totalWinAmount = wonBets.reduce((prev, curr) => {
		return prev + curr.possibleWin;
	}, 0);

	const totalBettedAmount = placedBets.reduce((prev, curr) => {
		return prev + parseFloat(curr.amount);
	}, 0);

	const profit = totalWinAmount - totalBettedAmount;

	return {
		...rest,
		profit,
		winRate,
		placedBets,
		totalVolume,
		totalPlayedBets: placedBets.length,
	};
};

export { useSportsbookStats };
