import { StatusPill } from "@components/atoms";
import { Bet } from "azuro-service/src/queries/getBets";
import React from "react";
import { cx } from "src/utils";

const BetStatusPill: React.FC<{ className?: string; bet: Bet }> = ({
	className,
	bet,
}) => {
	if (bet?.isActive && !bet.isLose) {
		return (
			<StatusPill
				className={cx("", className)}
				type="COMING_SOON"
				text="Active"
			/>
		);
	}

	if (bet.isWin) {
		<StatusPill className={cx("", className)} type="GREEN" text="Won" />;
	}

	if (bet.isCanceled) {
		return (
			<StatusPill
				className={cx("", className)}
				type="COMING_SOON"
				text="Canceled"
			/>
		);
	}

	if (bet.isLose && !bet.isCanceled) {
		return (
			<StatusPill className={cx("", className)} type="RED" text="Lost" />
		);
	}

	return <div className={cx(" ", className)}></div>;
};

export { BetStatusPill };
