import React from "react";
import GameCardSkeleton from "../../GameCard/skeleton/GameCardSkeleton";

const CountryLeaguesSkeleton = () => {
	return (
		<div
			className="bg-background-secondary
col-span-full rounded-lg px-4 py-3"
		>
			<div className="bg-content-primary/10 h-6 w-28 animate-pulse" />
			<div className="mt-4 flex flex-col gap-2">
				<GameCardSkeleton />
				<GameCardSkeleton />
				<GameCardSkeleton />
				<GameCardSkeleton />
				<GameCardSkeleton />
				<GameCardSkeleton />
			</div>
		</div>
	);
};

export default CountryLeaguesSkeleton;
