import React from "react";
import { Icon } from "@components/atoms";
import { type BetOutcome } from "@azuro-org/sdk";

const PlayedMatchCardStatus: React.FC<{ bet: BetOutcome }> = ({ bet }) => {
	const isLive = bet.game.status === "Live";
	return (
		<>
			{bet.isWin && (
				<div className="bg-badge-background-success text-badge-content-success flex h-5 w-5 items-center justify-center rounded-full  text-xs">
					W
				</div>
			)}
			{bet.isLose && (
				<div className="bg-background-badge-error text-content-badge-error flex h-5 w-5 items-center justify-center rounded-full  text-xs">
					L
				</div>
			)}

			{bet.isCanceled && (
				<div className="content-badge-alert text-content-badge-alert x flex h-5 w-5 items-center justify-center rounded-full  text-xs">
					C
				</div>
			)}

			{isLive && (
				<div className="bg-content-primary rounded-full  p-1">
					<Icon
						type="ACTIVE"
						className="stroke-content-primary h-4 w-4"
					/>
				</div>
			)}
		</>
	);
};

export { PlayedMatchCardStatus };
