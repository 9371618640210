import React from "react";

const NoEventsCard = () => {
	return (
		<div
			className="
				bg-background-secondary flex flex-col items-center justify-center rounded-lg px-4 py-28"
		>
			<img
				src="/sportsbook/calendar.png"
				alt="calendar"
				className="h-16 w-16"
			/>
			<span className="text-lg">No available events</span>
			<span className="text-content-secondary text-xs">
				Try again later or check some other events.
			</span>
		</div>
	);
};

export default NoEventsCard;
