import React from "react";
import dayjs from "dayjs";

import { cx } from "src/utils";
import { Icon } from "@components/atoms";
import { TooltipNumberWrapper } from "@components/molecules";
import { BetOutcome } from "azuro-service/src/queries/getBets";
import { PlayedMatchCardStatus } from "./PlayedMatchCardStatus/PlayedMatchCardStatus";
import { useLoadImageQuery } from "business-logic-gamblino";

const PlayedMatchCard: React.FC<{ className?: string; bet: BetOutcome }> = ({
	className,
	bet,
}) => {
	const participants = `${bet?.game?.participants[0].name || ""} - ${
		bet?.game?.participants[1].name || ""
	}`;

	const countryAndLeague = `${bet?.game?.league?.country?.name || ""} · ${
		bet?.game?.league?.name || ""
	}`;

	const { isError: firstParticipantImageError } = useLoadImageQuery({
		src: bet.game?.participants[0].image || "",
	});

	const { isError: secondParticipantImageError } = useLoadImageQuery({
		src: bet.game?.participants[1].image || "",
	});

	return (
		<div
			className={cx(
				" bg-background-tertiary shadow-violet-light flex min-h-[56px] w-full shrink-0 flex-col gap-2 rounded p-2",
				{
					"shadow-[inset_0px_-8px_16px_-12px_#4ADE80]": bet.isWin,
				},
				{
					"shadow-[inset_0px_-8px_16px_-12px_#F43F5E]": bet.isLose,
				},
				className,
			)}
		>
			<div className="flex justify-between">
				<p className="text-xs">{countryAndLeague}</p>
				<PlayedMatchCardStatus bet={bet} />
			</div>
			<div className="flex w-full flex-col gap-2 xl:flex-row">
				<div className="flex flex-1 flex-row items-center gap-5">
					<div className="flex flex-row items-center gap-1">
						<img
							src={
								!firstParticipantImageError
									? (bet?.game?.participants[0]
											.image as string)
									: "/gamblino-logo-without-text.svg"
							}
							className="h-7 w-7"
						/>
						<img
							src={
								!secondParticipantImageError
									? (bet?.game?.participants[1]
											.image as string)
									: "/gamblino-logo-without-text.svg"
							}
							className="h-7 w-7"
						/>
					</div>
					<div className="flex flex-col">
						<div className="text-content-secondary flex gap-1">
							<p className="text-xs">
								{dayjs
									.unix(bet?.game.startsAt)
									.format("D MMM YYYY")}
							</p>
							<p className="flex items-center gap-1 text-xs">
								<Icon
									type="CLOCK"
									className="stroke-content-primary h-3 w-3"
								/>
								{dayjs.unix(bet?.game.startsAt).format("HH:mm")}
							</p>
						</div>

						<p className={cx("text-content-primary text-xs")}>
							{participants}
						</p>
					</div>
				</div>
				<div className="flex flex-1 justify-between text-xs xl:justify-around">
					<div>
						<p className="text-content-secondary">Market</p>
						<p>{bet.marketName}</p>
					</div>
					<div>
						<p className="text-content-secondary">Bet on</p>
						<p>{bet.selectionName}</p>
					</div>
					<div>
						<p className="text-content-secondary">Odds</p>
						<p>
							<TooltipNumberWrapper
								number={bet.isCanceled ? "1" : bet.odds}
							/>
						</p>
					</div>
				</div>
			</div>
		</div>
	);
};

export { PlayedMatchCard };
