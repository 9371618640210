import React, { useState } from "react";
import { Icon, Tooltip } from "@components/atoms";
import { cx } from "src/utils";
import { Condition, Game } from "azuro-service/src/gql/graphql";
import { getMarketDescription, getMarketName } from "@azuro-org/dictionaries";
import BetButton from "src/Pages/GamesListPage/BetButton/BetButton";
import { AnimatePresence, motion } from "framer-motion";
import { AnimateChangeInHeight } from "@components/organisms";

type Props = {
	condition: Condition;
	game: Game;
};

const MarketAccordion = ({ condition, game }: Props) => {
	const [isOpen, setIsOpen] = useState(true);
	return (
		<div>
			<div className="bg-background-secondary cursor-pointer select-none rounded-lg">
				<div
					onClick={() => setIsOpen(!isOpen)}
					className=" flex w-full flex-row items-center justify-between px-4 py-2 text-xs"
				>
					<div className="flex flex-row items-center gap-1">
						<span>
							{getMarketName({
								outcomeId: condition.outcomes[0].outcomeId,
							})}
							?
						</span>
						<Tooltip
							content={getMarketDescription({
								outcomeId: condition.outcomes[0].outcomeId,
							})}
						>
							<Icon
								type="INFO"
								className="stroke-content-primary h-5 w-5"
							/>
						</Tooltip>
					</div>
					<Icon
						type="CHEVRON_DOWN"
						className={cx("stroke-content-primary h-5 w-5", {
							"rotate-180": !open,
						})}
					/>
				</div>
				<AnimateChangeInHeight duration={0.3}>
					<AnimatePresence mode="popLayout">
						{isOpen && (
							<motion.div
								initial={{ opacity: 0 }}
								animate={{ opacity: 1 }}
								exit={{ opacity: 0 }}
								className="px-4 pb-2 "
							>
								<div className=" text-content-primary flex flex-row gap-2 rounded-[4px]">
									{condition.outcomes.map((outcome) => (
										<BetButton
											key={outcome.id}
											condition={condition}
											outcome={outcome}
											game={game}
										/>
									))}
								</div>
							</motion.div>
						)}
					</AnimatePresence>
				</AnimateChangeInHeight>
			</div>
		</div>
	);
};

export default MarketAccordion;
