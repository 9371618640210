// CountryLeagues.tsx
import { Country } from "azuro-service/src/gql/graphql";
import React from "react";
import { filterGamesByTimeslot } from "../helpers";
import GameCard from "../GameCard/GameCard";
import { useSearchParams } from "react-router-dom";

export const CountryLeagues: React.FC<{
	country: Country;
}> = ({ country }) => {
	const [searchParams] = useSearchParams();
	const timeslot = searchParams.get("timeslot") || "all";

	return (
		<>
			{country.leagues.map((league) => {
				const filteredGames = league.games.filter((game) =>
					filterGamesByTimeslot(timeslot, game),
				);

				if (filteredGames.length === 0) return null;

				return (
					<div
						key={`${country.name}-${league.name}`}
						className="bg-background-secondary
col-span-full rounded-lg px-4 py-3"
					>
						<span>
							{country.name} - {league.name}
						</span>
						<div className="mt-4 flex flex-col gap-2">
							{filteredGames.map((game) => {
								return <GameCard key={game.id} game={game} />;
							})}
						</div>
					</div>
				);
			})}
		</>
	);
};
