import React from "react";
import { useRedeemBet } from "@azuro-org/sdk";

import { cx } from "src/utils";
import { Button } from "@components/molecules";
import { Bet } from "azuro-service/src/queries/getBets";
import { useModalManager } from "@components/ethereals/ModalsWrapper";
import { azuroBussinessLogicApi } from "business-logic-gamblino";
import { useAppDispatch } from "src/redux/store";

const RedeemBetButton: React.FC<{ className?: string; bet: Bet }> = ({
	className,
	bet,
}) => {
	const { submit, data, isPending, isProcessing } = useRedeemBet();

	const dispatch = useAppDispatch();
	const { modalManager } = useModalManager();

	React.useEffect(() => {
		if (data) {
			modalManager.open("SuccessSeeTxModal", {
				heading: "Success",
				description: `You successfully claimed the bet.`,
				txHash: data.hash,
			});
			dispatch(azuroBussinessLogicApi.util.invalidateTags(["Bets"]));
		}
	}, [data?.hash]);

	if (bet.isRedeemable && bet.isWin)
		return (
			<Button
				onClick={async () => {
					await submit({
						bets: [bet],
					});
				}}
				disabled={isPending || isProcessing || !!data}
				className={cx("bg-purple-gradient h-6", className)}
			>
				<Button.Text className="text-content-primary  text-xs">
					Claim winning
				</Button.Text>
			</Button>
		);
	if (bet.isRedeemable && bet.isCanceled)
		return (
			<Button
				onClick={async () => {
					await submit({
						bets: [bet],
					});
				}}
				disabled={isPending || isProcessing || !!data}
				className={cx("bg-purple-gradient h-6", className)}
			>
				<Button.Text className="text-content-primary  text-xs">
					Claim
				</Button.Text>
			</Button>
		);
	if (bet.isRedeemed)
		return (
			<Button
				disabled
				onClick={() => {}}
				className={cx(
					"bg-content-primary h-6 bg-opacity-20",
					className,
				)}
			>
				<Button.Icon
					type="CHECKMARK"
					className="stroke-violet-dark w-4"
				/>
				<Button.Text className="text-gamblino-light text-xs">
					Claimed
				</Button.Text>
			</Button>
		);

	return <div className={cx(" ", className)}></div>;
};

export { RedeemBetButton };
