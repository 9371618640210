import { getMarketName } from "@azuro-org/dictionaries";
import { Condition, Country, Game } from "azuro-service/src/gql/graphql";
import dayjs from "dayjs";

const findConditionByMarket = (conditions: Condition[], marketName: string) => {
	return conditions.find((condition) =>
		condition.outcomes.find(
			(outcome) =>
				getMarketName({ outcomeId: outcome.outcomeId }) === marketName,
		),
	);
};

const getMarketsFromCountries = (countries: Country[]) => {
	const markets: string[] = [];

	if (!countries) return markets;

	for (const country of countries) {
		for (const league of country.leagues) {
			for (const game of league.games) {
				for (const condition of game.conditions) {
					const marketName = getMarketName({
						outcomeId: condition.outcomes[0].outcomeId,
					});
					if (!markets.includes(marketName)) {
						markets.push(marketName);
					}
				}
			}
		}
	}
	return markets;
};

const filterGamesByTimeslot = (timeslot: string, game: Game) => {
	switch (timeslot) {
		case "all":
			return game.startsAt > dayjs().unix();
		case "today":
			return (
				game.startsAt > dayjs().unix() &&
				game.startsAt < dayjs().endOf("day").unix()
			);
		case "tomorrow":
			return (
				game.startsAt > dayjs().add(1, "day").startOf("day").unix() &&
				game.startsAt < dayjs().add(1, "day").endOf("day").unix()
			);
		case "1h":
			return (
				game.startsAt < dayjs().add(1, "hour").unix() &&
				game.startsAt >= dayjs().unix()
			);
		case "3h":
			return (
				game.startsAt < dayjs().add(3, "hour").unix() &&
				game.startsAt >= dayjs().unix()
			);
		case "6h":
			return (
				game.startsAt < dayjs().add(6, "hour").unix() &&
				game.startsAt >= dayjs().unix()
			);
		default:
			return game.startsAt > dayjs().startOf("day").unix();
	}
};

export {
	findConditionByMarket,
	getMarketsFromCountries,
	filterGamesByTimeslot,
};
