import { RadioGroup } from "@headlessui/react";
import React from "react";
import { cx } from "src/utils";
import TimeslotOption from "./TimeslotOption";

type Props = {
	timeslot: string;
	handleChangeTimeslot: (value: string) => void;
};

const SportsbookTimeslots = ({ timeslot, handleChangeTimeslot }: Props) => {
	return (
		<RadioGroup
			value={timeslot}
			onChange={handleChangeTimeslot}
			className="order-first flex flex-row items-center gap-1 md:order-last"
		>
			<RadioGroup.Label className="sr-only">Timeslot</RadioGroup.Label>
			<TimeslotOption value="all" label="All" />
			<TimeslotOption value="today" label="Today" />
			<TimeslotOption value="tomorrow" label="Tomorrow" />{" "}
			<TimeslotOption value="1h" label="1h" />
			<TimeslotOption value="3h" label="3h" />{" "}
			<TimeslotOption value="6h" label="6h" />
		</RadioGroup>
	);
};

export default SportsbookTimeslots;
