import React, { useState } from "react";
import { AnimatePresence } from "framer-motion";

import { TabsToggle } from "./TabsToggle/TabsToggle";
import { BetSlipTab } from "./BetSlipTab/BetSlipTab";
import { MyPlacedBetsTab } from "./MyPlacedBetsTab/MyPlacedBetsTab";

const BetsTabs: React.FC<unknown> = () => {
	const [selected, setSelected] = useState(false);

	return (
		<div className="">
			<TabsToggle
				selected={selected}
				onSelectedChange={(newSelected) => {
					setSelected(newSelected);
				}}
			/>

			<AnimatePresence mode="popLayout">
				{selected ? <MyPlacedBetsTab /> : <BetSlipTab />}
			</AnimatePresence>
		</div>
	);
};

export default BetsTabs;
