import React from "react";
import { CountryLeagues } from "../CountryLeagues/CountryLeagues";
import { Sport, SportHub } from "azuro-service/src/gql/graphql";
import { useParams, useSearchParams } from "react-router-dom";
import { filterGamesByTimeslot } from "../helpers";
import NoEventsCard from "../NoEventsCard/NoEventsCard";
import { azuro, useGamesListQuery } from "business-logic-gamblino";
import CountryLeaguesSkeleton from "../CountryLeagues/skeleton/CountryLeaguesSkeleton";

const GamesList: React.FC = () => {
	const { sportSlug } = useParams();
	const [searchParams] = useSearchParams();

	const timeslot = searchParams.get("timeslot") || "all";

	const sportType = ["dota-2", "csgo"].includes(sportSlug || "")
		? "esports"
		: "sports";

	const { data, isLoading, isFetching, error } = useGamesListQuery({
		sportSlug: sportSlug || "football",
		sportsId: sportType,
		countrySlug: searchParams.get("country") || "",
		leagueSlug: searchParams.get("league") || "",
	});

	const {
		data: fallbackData,
		isLoading: isLoadingFB,
		isFetching: isFetchingFB,
	} = azuro.useGamesListQuery(
		{
			hotSports: false,
			variables: {
				sportsSlug: sportSlug || "football",
				countrySlug: searchParams.get("country") || "",
				leagueSlug: searchParams.get("league") || "",
			},
		},
		{
			skip: !error,
		},
	);

	if (isLoading || isFetching || isLoadingFB || isFetchingFB)
		return [...Array(5)].map((num, idx) => (
			<CountryLeaguesSkeleton key={idx} />
		));

	if ((!data && !error) || (error && !fallbackData)) return null;

	const sportHub =
		(fallbackData
			? (fallbackData.find(
					(fbData) => fbData.name === sportType,
				) as SportHub)
			: (data?.data.sportHubs.find(
					(item) => item.name === sportType,
				) as SportHub)) || [];

	const sports = sportHub.sports?.filter(
		(sport) => sport.countries.length > 0,
	) as Sport[];

	const countries = sports
		?.map((sport) => sport.countries)
		.flat()
		.filter((country) => country.leagues.length > 0);

	if (!countries) return null;

	const leagues = countries.map((country) => country.leagues).flat();

	const hasEvents =
		countries.length > 0 &&
		leagues.length > 0 &&
		countries.some((country) =>
			country.leagues.some((league) =>
				league.games.some((game) =>
					filterGamesByTimeslot(timeslot, game),
				),
			),
		);

	return hasEvents ? (
		countries.map((country) => (
			<CountryLeagues key={country.id} country={country} />
		))
	) : (
		<NoEventsCard />
	);
};

export default GamesList;
