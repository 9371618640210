import React from "react";

const MarketAccotdionSkeleton = () => {
	return (
		<div>
			<div className=" bg-background-secondary cursor-pointer select-none rounded-lg ">
				<div className=" flex w-full flex-row items-center justify-between px-4 py-2 text-xs">
					<div className="flex flex-row items-center gap-1">
						<div className="bg-content-primary/10 h-4 w-16 animate-pulse rounded-sm" />
					</div>
				</div>
				<div>
					<div>
						<div className="px-4 pb-2 ">
							<div className="bg-background-tertiary text-content-primary flex flex-row gap-2 rounded-[4px] p-2">
								<div className="bg-content-primary/10 h-8 w-full animate-pulse rounded-sm" />
								<div className="bg-content-primary/10 h-8 w-full animate-pulse rounded-sm" />
								<div className="bg-content-primary/10 h-8 w-full animate-pulse rounded-sm" />
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export { MarketAccotdionSkeleton };
