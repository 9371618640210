import React from "react";
import { AnimatePresence, motion } from "framer-motion";

import { Button } from "@components/molecules";
import { MyBetsSideSection } from "../MyBetsSideSection";
import { Sheet, SheetContent, SheetTrigger } from "@components/atoms/Sheet";

import { useAppSelector } from "src/redux/store";
import { Icon } from "@components/atoms";

const MyBetsSideSectionMobile: React.FC<unknown> = () => {
	const bets = useAppSelector((state) => state.azuro);

	const betSlipTitle = bets.length ? `Betslip (${bets?.length})` : "Betslip";

	return (
		<AnimatePresence>
			<div className="sticky bottom-6 ml-auto mr-2 flex max-w-[180px] justify-end lg:hidden">
				<Sheet>
					<SheetTrigger className="bg-purple-gradient flex cursor-pointer flex-row items-center justify-center gap-1 rounded-full px-4 py-3">
						<Icon
							type="PAPER"
							className="stroke-content-primary h-6 w-6"
						/>
						<span>{betSlipTitle}</span>
					</SheetTrigger>

					<SheetContent
						overlayClassName="lg:backdrop-blur-none"
						side="bottom"
						className=" z-sheet w-full p-0 lg:hidden"
					>
						<motion.div
							initial={{ y: 200 }}
							animate={{ y: 0 }}
							exit={{ y: 200 }}
						>
							<MyBetsSideSection />
						</motion.div>
					</SheetContent>
				</Sheet>
			</div>
		</AnimatePresence>
	);
};

export { MyBetsSideSectionMobile };
